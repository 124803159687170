<template>
  <div>
    <b-modal
      id="creditClassDocumentModal"
      centered
      title="Danh sách giáo trình"
      size="xl"
      body-class="position-static"
      hide-footer
      @shown="onShown"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-row class="mt-2">
        <b-col cols="12">
          <div class="w-100 d-flex justify-content-end align-items-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="$bvModal.show('teacherDocumentModal')"
            >
              <span class="text-nowrap text-right">
                <feather-icon icon="UploadIcon" /> Chọn giáo trình
              </span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="dataSources"
            :pagination-options="paginationOptions"
            :line-numbers="true"
          >
            <div
              slot="emptystate"
              style="text-align: center; font-weight: bold"
            >
              Không có bản ghi nào !
            </div>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'name'">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-img
                      :src="getIconFile(props.formattedRow[props.column.field])"
                      height="23"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="media-body mb-0">
                      {{ props.formattedRow[props.column.field] }}
                    </h6>
                  </b-media-body>
                </b-media>
              </span>
              <span
                v-else-if="props.column.field === 'action'"
                class="d-flex align-items-center justify-content-center flex-wrap"
              >
                <b-button
                  v-b-tooltip.hover.top="'Tải về'"
                  variant="primary"
                  class="btn-icon mr-1"
                  size="sm"
                  @click="onDownload(props.row.path)"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Xóa'"
                  variant="danger"
                  class="btn-icon"
                  size="sm"
                  @click="onDelete(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị 1 đến </span>
                  <b-form-select
                    v-model="itemsPerPage"
                    :options="itemsPerPageOptions"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="itemsPerPage"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  />
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>

      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>

    <b-modal
      id="teacherDocumentModal"
      body-class="position-static"
      centered
      title="Tài liệu giảng viên"
      size="xl"
      no-close-on-backdrop
    >
      <TeacherDocument @selectDocuments="onSelectDocuments" />
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-if="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="onSaveCreditClassDocument()"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Cập nhật
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('teacherDocumentModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCol, BFormSelect, BImg, BMedia, BMediaAside, BMediaBody, BModal, BOverlay, BPagination, BRow, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TeacherDocument from '@/views/teacher-document/TeacherDocument.vue'
import { VueGoodTable } from 'vue-good-table'
import { getIconFile, hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'CreditClassDocument',
  components: {
    BMedia,
    BImg,
    BMediaAside,
    BMediaBody,
    TeacherDocument,
    BModal,
    BCol,
    BRow,
    BFormSelect,
    BOverlay,
    BPagination,
    BButton,
    VueGoodTable,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    creditClassId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Giáo trình',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập giáo trình',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Giảng viên',
          field: 'teacher',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập giảng viên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Người tạo',
          field: 'createdBy',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập người tạo',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
        },
      ],
      teacherDocuments: [],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'creditClassMedia/documents',
      url: 'creditClassMedia/url',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    isCreated() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CREDIT_CLASS_DOCUMENT)
    },
  },
  methods: {
    ...mapActions({
      getCreditClassDocuments: 'creditClassMedia/getCreditClassDocuments',
      createCreditClassDocuments: 'creditClassMedia/createCreditClassDocuments',
      downloadCreditClassDocument: 'creditClassMedia/downloadCreditClassDocument',
      deleteCreditClassDocument: 'creditClassMedia/deleteCreditClassDocument',
    }),
    async onShown() {
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getCreditClassDocuments({ creditClassId: this.creditClassId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        await this.downloadCreditClassDocument({ objectPath })
        if (this.url) {
          window.open(this.url, '_blank')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onDelete(id) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa giáo trình?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.deleteCreditClassDocument(id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  await this.getCreditClassDocuments({ creditClassId: this.creditClassId })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    onSelectDocuments(data) {
      this.teacherDocuments = [...data]
    },
    async onSaveCreditClassDocument() {
      const creditClassMedias = []
      if (this.teacherDocuments.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn tài liệu cần cập nhật',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.teacherDocuments.forEach(element => creditClassMedias.push({
        creditClassId: this.creditClassId,
        mediaId: element,
      }))
      this.isLoading = true
      try {
        const response = await this.createCreditClassDocuments({ creditClassMedias })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('teacherDocumentModal')
            await this.getCreditClassDocuments({ creditClassId: this.creditClassId })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.teacherDocuments = []
    },
    getIconFile,
  },
}
</script>
